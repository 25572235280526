import { post_form, post } from '@/utils/http'

// 获取活码信息
export const getQrcode = data => {
    return post('/hunter-api/qrcode/assign', data)
}

// 长按二维码
export const actionscanQrcode = data => {
    return post_form('/hunter-api/qrcode/action/scan', data, true)
}

export const getMiniUrlLink = function(appid, data) {
    return post(`/begonia-api/wxMa/urlLink/${appid}`, data)
}
