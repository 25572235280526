export const getUserInfo = function () {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = userInfo ? JSON.parse(userInfo) : null
    if (userInfo) {
        const status = parseInt(+new Date() - (userInfo.timeStamp || 0))
        if (status > (3 * 24 * 60 * 60 * 1000)) {
            clearUserInfo()
            return null
        }
    }
    return userInfo
}

export const setUserInfo = function (info) {
    let userInfo = localStorage.getItem('userInfo') || JSON.stringify({})
    userInfo = { ...JSON.parse(userInfo), ...info, timeStamp: +new Date() }
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    return userInfo
}

export const clearUserInfo = function () {
    localStorage.removeItem('userInfo')
}


// 静默授权
export const wxAuthOpenId = function (appId, componentAppId) {
    const redirect_uri = encodeURIComponent(window.location.href)
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE&component_appid=${componentAppId}#wechat_redirect`
    window.location.replace(url)

    // 微信后退导致 打开授权失败
    setTimeout(() => {
        window.location.reload()
    }, 5000)
}

// 用户基本信息授权
export const wxAuthUserInfo = function (appId, componentAppId) {
    const redirect_uri = encodeURIComponent(window.location.href)
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE&component_appid=${componentAppId}#wechat_redirect`
    window.location.replace(url)

    // 微信后退导致 打开授权失败
    setTimeout(() => {
        window.location.reload()
    }, 5000)
}


