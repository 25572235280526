const repairZero = function(num) {
    return num >= 10 ? num : `0${num}`
}

// 开发环境设置默认 用户信息
export const DEVlocalStorage = function(data) {
    if (process.env.NODE_ENV === 'development') {
        localStorage.setItem('userInfo', JSON.stringify(data))
    }
}

//地址参数字典
export const urlQuery = function(flag) {
    var tmp = {}
    var url = window.location.href
    var str = url.substring(url.lastIndexOf('?') + 1)
    str.replace(/([^?&=\/#]+)=([^?&=\/#]+)/g, function(res, $1, $2) {
        var name = decodeURIComponent($1)
        var val = decodeURIComponent($2)
        tmp[name] = val
    })
    return tmp
}

export const isWeChatClient = () => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    }
    return false
}

export const isMobile = function() {
    return /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(navigator.userAgent) ? true : false
}
