import Vue from 'vue'
import index from './index.vue'
import '../../scss/reset.css'
import { Toast, Icon } from 'vant'
import 'vant/lib/index.css'

Vue.use(Toast)
Vue.use(Icon)


window.SEVER_PATH = process.env.VUE_APP_SEVER_PATH
window.WX_APPID = process.env.VUE_APP_WX_APPID
Vue.config.productionTip = false

new Vue({
    render: h => h(index),
}).$mount('#index')
