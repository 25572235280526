<template>
    <div class="main">
        <div class="content-container" v-if="!isError">
            <div class="login-container" v-if="userAuthState">
                <div>
                    <img src="https://res.peki.vip/20220806/9a50de15760246e8b3ee04796fc93e02.png" />
                    <div class="desc">授权您的信息后，可使用完整服务。</div>
                    <div class="btns">
                        <div class="login-btn" @click="onUserAuth">微信授权</div>
                    </div>
                </div>
            </div>
            <template v-else>
                <div class="notice" v-if="isShowNotice && qrcodeUrl">
                    <van-icon name="checked" />
                    <span>此二维码已通过安全验证，请放心扫码</span>
                    <van-icon @click="onCloseNotice" name="cross" />
                </div>
                <img
                    class="group-qrcode"
                    v-if="type === 1"
                    @touchstart="onTouchstart"
                    @touchend="onTouchend"
                    :src="qrcodeUrl"
                />
                <div v-else-if="type === 2" class="customer-service">
                    <div v-show="qrcodeScale === 1">
                        <img
                            v-if="options.qrcodeNo === 'tscode'"
                            src="https://res.peki.vip/20220123/ef225596f7144a29a9d2674c4ca03c10.png"
                        />
                        <img v-else src="https://res.peki.vip/20210523/1621762115998/1621762115992.png" />
                        <img
                            class="service-qrcode"
                            @load="onQrcodeLoad"
                            @touchstart="onTouchstart"
                            @touchend="onTouchend"
                            :src="qrcodeUrl"
                        />
                    </div>
                    <div v-show="qrcodeScale !== 1" class="compound-qrcode">
                        <img @touchstart="onTouchstart" @touchend="onTouchend" :src="qrcodeUrl" />
                    </div>
                </div>
            </template>
        </div>
        <div class="error-container" v-else>
            <img src="@/images/error-icon.png" />
            <div v-if="isWeChatEnv">该页面已失效，请重新扫码访问。</div>
            <div v-else>请用微信打开打开此页面</div>
        </div>

        <div class="fixed-btn" v-if="planBUrl" @click="isShowErrorDialog = true">
            <span>联系</span>
            <span>客服</span>
        </div>
        <div class="dialog-container" v-show="isShowErrorDialog" @click="isShowErrorDialog = false">
            <div class="error-dialog" @click.stop="">
                <div class="title">如无法进群或者其他原因，请加我微信</div>
                <div class="title">(长按识别下方二维码)</div>
                <img class="wx-code" :src="planBUrl" />
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { getQrcode, actionscanQrcode, getMiniUrlLink } from './api'
import { urlQuery, isWeChatClient, isMobile } from '@/utils/tools.js'
import { wxAuthOpenId, wxAuthUserInfo } from '@/utils/login.js'

const _logoMap = {
    peki: 'https://res.peki.vip/20240222/0ae4339c9fe149abb61c69ed072c30fa.png',
    PEKI: 'https://res.peki.vip/20240222/0ae4339c9fe149abb61c69ed072c30fa.png',
    cake: 'https://res.peki.vip/20240222/4ba67854a17d45f1b501eac80a94a888.png',
    CAKE: 'https://res.peki.vip/20240222/4ba67854a17d45f1b501eac80a94a888.png',
    george: 'https://res.peki.vip/20240222/d8f406cf182a433493c5d0313493460d.jpg',
    GEORGE: 'https://res.peki.vip/20240222/d8f406cf182a433493c5d0313493460d.jpg',
    clip: 'https://res.peki.vip/20240222/311800290adb48b1931a0511944f9027.png',
    CLIP: 'https://res.peki.vip/20240222/311800290adb48b1931a0511944f9027.png',
}
export default {
    name: 'group-qrcode',
    data() {
        return {
            logoUrl: '',
            isWeChatEnv: false,
            isShowNotice: true,
            isShowErrorDialog: false,
            isError: false,
            userAuthState: false,
            qrcodeUrl: '',
            planBUrl: '',

            type: 0,
            options: {},
            qrcodeScale: 0,
        }
    },
    created() {
        // 小程序[wx93ad102e24de3ea8]写死了活码地址
        this.historyId = ''
        this.longTap = null
        this.trackId = localStorage.getItem('uuid')
        if (!this.trackId) {
            const trackId = uuidv4().replace(/-/g, '')
            localStorage.setItem('uuid', trackId)
            this.trackId = trackId
        }

        this.options = urlQuery()
        if (this.options.qrcodeNo) {
            if (this.options.qrcodeNo === 'YodA') {
                window.location.href = 'https://tf.peki.vip/HeF/index.html'
            } else {
                this.getQrcodeApi()
            }
        } else {
            this.isError = true
        }
    },
    mounted() {},
    methods: {
        onTouchstart() {
            clearTimeout(this.longTap)
            this.longTap = setTimeout(() => {
                actionscanQrcode({
                    trackId: this.trackId,
                    qrcodeNo: this.options.qrcodeNo,
                    historyId: this.historyId,
                })
                    .then(() => {})
                    .catch(() => {})
            }, 500)
        },
        onTouchend() {
            clearTimeout(this.longTap)
        },
        onCloseNotice() {
            this.isShowNotice = false
        },
        onQrcodeLoad(event) {
            this.qrcodeScale = event.target.width / event.target.height
        },

        onUserAuth() {
            wxAuthUserInfo(this.authInfo.appId, this.authInfo.componentAppId)
            window.localStorage.setItem('wxAuthState', true)
        },
        getQrcodeApi() {
            const params = {
                trackId: this.trackId,
                qrcodeNo: this.options.qrcodeNo,
            }
            if (this.options.ext) {
                params.ext = this.options.ext
            }

            // 防止重复使用code
            const state = window.localStorage.getItem('wxAuthState')
            const historyCode = window.localStorage.getItem(this.options.code || 'localStorage')
            if (state && this.options.code && !historyCode) {
                params.code = this.options.code
                window.localStorage.removeItem('wxAuthState')
                window.localStorage.setItem(this.options.code, true)
            }
            // 开发时： appid openid unionid 跳过授权
            getQrcode(params).then(res => {
                if (res.data && res.data.projectNo) {
                    this.logoUrl = _logoMap[res.data.projectNo] || ''
                }
                this.isWeChatEnv = isWeChatClient()
                // 当需要微信授权时，用户不在微信环境内，且是移动端
                if ([601, 602].includes(res.code) && !this.isWeChatEnv && isMobile()) {
                    getMiniUrlLink('wx93ad102e24de3ea8', {
                        path: '/pages/qrcodeWebview/qrcodeWebview',
                        query: `redirect=${encodeURIComponent(window.location.href)}`,
                    }).then(res => {
                        window.location.href = res.data
                    })
                    return
                }

                // 当需要微信授权时，且用户不在微信环境内，展示错误
                if ([601, 602].includes(res.code) && !this.isWeChatEnv) {
                    return (this.isError = true)
                }
                if (res.code == 601) {
                    wxAuthOpenId(res.data.appId, res.data.componentAppId)
                    window.localStorage.setItem('wxAuthState', true)
                    return
                } else if (res.code == 602) {
                    this.authInfo = res.data
                    this.userAuthState = true
                    return
                }
                this.historyId = res.data.historyId
                this.type = res.data.type
                // type5 网页码
                if (this.type === 5) {
                    window.location.href = res.data.qrcodeUrl
                } else {
                    if (res.data.qrcodeUrl) {
                        this.qrcodeUrl = res.data.qrcodeUrl
                    }
                    if (res.data.planBUrl) {
                        this.planBUrl = res.data.planBUrl
                    }
                }
                document.title = res.data.title || ''
            })
        },
    },
}
</script>

<style lang="scss">
.main {
    max-width: 640px;
    margin: 0 auto;
}
.content-container {
    width: 100%;
    position: relative;
    padding-top: 28px;
    .notice {
        position: absolute;
        top: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        color: #7dc766;
        background: #333333;
        padding: 0 12px;
        box-sizing: border-box;
        z-index: 9;
        span {
            font-size: 14px;
            margin-left: 6px;
        }
        .van-icon-cross {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            color: #ffffff;
            font-size: 16;
        }
    }
    .customer-service {
        position: relative;
        .service-qrcode {
            position: absolute;
            top: 33.5%;
            left: 50%;
            transform: translateX(-48.5%);
            width: 48%;
        }
    }
    .compound-qrcode {
        img {
            width: 100%;
            display: flex;
        }
    }
}
.error-container {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
        margin: 20px 0 0 20px;
        padding-bottom: 200px;
    }
    img {
        width: 300px;
        height: 200px;
    }
}

.dialog-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.8);
}

.fixed-btn {
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #aaaaaa;
    color: #ffffff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.2em;
}
.error-dialog {
    width: 90%;
    max-width: 350px;
    border-radius: 4px;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    .title {
        font-size: 13px;
        text-align: center;
        line-height: 1.4em;
    }
    .wx-code {
        width: 100%;
    }
}

.login-container {
    & > div {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    img {
        width: 100px;
        padding: 70px 0 10px;
    }
    .desc {
        font-size: 14px;
        text-align: center;
        color: #666;
    }
    .btns {
        margin-top: 180px;
    }
    .login-btn {
        padding: 10px 0;
        background: #6dbd6f;
        border-radius: 4px;
        color: #ffffff;
        letter-spacing: 2px;
        font-size: 14px;
        width: 120px;
        text-align: center;
        font-weight: 700;
    }
}
</style>
