import axios from 'axios'
import { Toast } from 'vant'
import qs from 'qs'

const http = (method, postType, path, data, isCatch) => {
    return new Promise((resolve, reject) => {
        const SEVER_PATH = window.SEVER_PATH
        const headers = {
            module: 'hunter',
            channel: 'h5',
            ['content-Type']: 'application/json',
        }
        if (postType === 'form') {
            headers['content-Type'] = 'application/x-www-form-urlencoded'
        }
        axios({
            method,
            url: SEVER_PATH + path,
            headers: headers,
            data,
            withCredentials: true,
        })
            .then(res => {
                // 601, 602 在 group-qrcode.html 中使用 需要透传下去
                if (res.status == 200 && [601, 602, 200].includes(res.data.code)) {
                    resolve(res.data)
                } else {
                    if (isCatch) {
                        reject(res)
                    } else {
                        Toast(res.data.message)
                    }
                }
            })
            .catch(err => {
                if (isCatch) {
                    reject(err)
                }
            })
    })
}

export const post = function(path, data, isCatch) {
    return http('POST', '', path, data, isCatch)
}

export const post_form = function(path, data, isCatch) {
    return http('POST', 'form', path, qs.stringify(data), isCatch)
}

